<template>
  <v-autocomplete
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
    color="tertiary"
    :label="$t('hr.travel.travel')"
    item-value="id"
    :menu-props="{ offsetY: true, overflowY: true }"
    :loading="loading || fetching"
    :items="items"
    :filter="customFilter"
    @input="$emit('input', $event)"
  >
    <template v-slot:selection="{ item, index }">
      <v-list-item style="width: 100%;">
        <v-list-item-avatar size="30" color="red" class="headline font-weight-light white--text">
          <v-img
            v-if="$helpers.get(item.employee, 'profile.imageUrl')"
            :src="$helpers.get(item.employee, 'profile.imageUrl')"
          />
          <span v-else class="caption">
            {{
              `${item.employee.firstName.substring(0, 1).toUpperCase()}${item.employee.lastName
                .substring(0, 1)
                .toUpperCase()}`
            }}
          </span>
        </v-list-item-avatar>
        <v-list-item-content class="ml-2">
          <v-list-item-title class="body-2">
            {{ `${item.employee.firstName} ${item.employee.lastName}` }}
            <span class="caption">
              ({{ item.beginDate | formattedDateTimeMinute }} - {{ item.endDate | formattedDateTimeMinute }})
            </span>
          </v-list-item-title>
          <v-list-item-subtitle> {{ item.country }} - {{ item.city }} </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:item="{ item, tile }">
      <v-list-item-avatar size="30" color="red" class="headline font-weight-light white--text">
        <v-img
          v-if="$helpers.get(item.employee, 'profile.imageUrl')"
          :src="$helpers.get(item.employee, 'profile.imageUrl')"
        />
        <span v-else class="caption">
          {{
            `${item.employee.firstName.substring(0, 1).toUpperCase()}${item.employee.lastName
              .substring(0, 1)
              .toUpperCase()}`
          }}
        </span>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="body-2">
          {{ `${item.employee.firstName} ${item.employee.lastName}` }}
          <span class="caption">
            ({{ item.beginDate | formattedDateTimeMinute }} - {{ item.endDate | formattedDateTimeMinute }})
          </span>
        </v-list-item-title>
        <v-list-item-subtitle> {{ item.country }} - {{ item.city }} </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2020-12-21 08:57:21
   * @modify date 2020-12-21 08:57:21
   * @desc Travel Picker
   */
  import gql from "graphql-tag";
  import { LEAVE_REQUEST_STATUS } from "@/helpers/enums";

  export default {
    name: "TravelPicker",
    inheritAttrs: false,
    props: {
      value: String,
      loading: Boolean
    },
    data: vm => ({
      fetching: false,
      items: []
    }),
    methods: {
      customFilter(item, queryText) {
        const firstName = item.employee.firstName.toLocaleLowerCase(navigator.language);
        const lastName = item.employee.lastName.toLocaleLowerCase(navigator.language);
        const searchText = queryText.toLocaleLowerCase(navigator.language);
        return (
          firstName.indexOf(searchText) > -1 ||
          lastName.indexOf(searchText) > -1 ||
          item.startDate.indexOf(queryText) > -1 ||
          item.finishDate.indexOf(queryText) > -1
        );
      },
      fetchItems() {
        const options = {
          query: gql`
            query filterTravels($criteria: TravelFilterCriteria!) {
              filterTravels(criteria: $criteria) {
                id
                requestStatus
                beginDate
                endDate
                country
                city
                employee {
                  firstName
                  lastName
                  profile {
                    imageUrl
                  }
                }
              }
            }
          `,
          variables: {
            criteria: {
              employee: this.$store.state.auth.user.id
            }
          },
          fetchPolicy: "no-cache"
        };
        this.fetching = true;
        this.$apollo
          .query(options)
          .then(({ data: { filterTravels } }) => {
            this.items = filterTravels.filter(travel => travel.requestStatus === LEAVE_REQUEST_STATUS.APPROVED);
          })
          .catch(console.error)
          .finally(() => (this.fetching = false));
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>

<style lang="scss" scoped></style>
